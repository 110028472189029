<template>
    <div class="row">
        <div class="col-12 form-group">
            <label>Size<span class="text-danger">*</span></label>
            <input
                v-model.number="size"
                type="text"
                class="form-control"
                placeholder="Size"
                :class="{ 'is-invalid': $v.size.$error }"
                required
            />
        </div>
        <div class="col-6 form-group mb-3">
            <label>EU Size Men<span class="text-danger">*</span></label>
            <input
                v-model.number="euSizeMen"
                type="text"
                class="form-control"
                placeholder="Size"
                :class="{ 'is-invalid': $v.euSizeMen.$error }"
                required
            />
        </div>
        <div class="col-6 form-group mb-3">
            <label>EU Size Women<span class="text-danger">*</span></label>
            <input
                v-model.number="euSizeWomen"
                type="text"
                class="form-control"
                placeholder="Size"
                :class="{ 'is-invalid': $v.euSizeWomen.$error }"
                required
            />
        </div>

        <div class="col-12 text-center">
            <button
                type="button"
                class="mx-2 btn btn-success waves-effect waves-light"
                @click="onSaveAttributeSize"
            >
                <i class="fas fa-plus-circle mr-1" />
                Add New Size
            </button>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            size: null,
            euSizeMen: null,
            euSizeWomen: null
        };
    },

    validations: {
        size: {
            required
        },
        euSizeMen: {
            required
        },
        euSizeWomen: {
            required
        }
    },

    methods: {
        onSaveAttributeSize() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            return this.$emit('save-size', {
                size: this.size,
                euSizeMen: this.euSizeMen,
                euSizeWomen: this.euSizeWomen
            });
        }
    }
};
</script>
