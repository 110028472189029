var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.pageTitle,"items":_vm.breadcrumbsItems}}),_c('div',{staticClass:"row"},[(_vm.sizes)?_c('div',{staticClass:"col-12 col-xl-6"},[_c('div',{staticClass:"card-box"},[_c('h5',{staticClass:"text-uppercase bg-light p-2 mt-0 mb-0"},[_vm._v("\n                    Sizes\n                    "),_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.add-size-collapse",modifiers:{"add-size-collapse":true}}],staticClass:"mx-2 btn btn-sm btn-light waves-effect waves-light",attrs:{"type":"button"}},[_c('i',{staticClass:"fe-chevron-down"})])]),_c('b-collapse',{staticClass:"p-2 bg-light",attrs:{"id":"add-size-collapse","visible":true}},[_c('attributes-sizes-add',{on:{"save-size":_vm.onSaveSize}})],1),_c('awesome-table',{staticClass:"mt-3",attrs:{"labels":_vm.labels,"items":_vm.sizes,"options":{
                        drag: true,
                        sort: true,
                        isCard: false,
                        addButton: false,
                        searchInput: false,
                        link: false,
                        tableClass: 'table-sm'
                    },"pagination":_vm.pagination},on:{"row-click":_vm.showUpdateModal,"pagination-change":_vm.onPaginationChange,"elements-moved":_vm.onElementsMoved},scopedSlots:_vm._u([{key:"items.actions",fn:function(ref){
                    var item = ref.item;
return [_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDeleteSize(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,true)})],1)]):_vm._e()]),_c('update-modal',{attrs:{"id":'sizeUpdateModal',"size":_vm.sizeToUpdate},on:{"updated":_vm.onSizeUpdated,"hidden":_vm.onHideUpdateModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }