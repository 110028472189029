<template>
    <layout>
        <page-header :title="pageTitle" :items="breadcrumbsItems" />

        <div class="row">
            <div v-if="sizes" class="col-12 col-xl-6">
                <div class="card-box">
                    <h5 class="text-uppercase bg-light p-2 mt-0 mb-0">
                        Sizes
                        <button
                            v-b-toggle.add-size-collapse
                            type="button"
                            class="mx-2 btn btn-sm btn-light waves-effect waves-light"
                        >
                            <i class="fe-chevron-down" />
                        </button>
                    </h5>
                    <b-collapse
                        id="add-size-collapse"
                        :visible="true"
                        class="p-2 bg-light"
                    >
                        <attributes-sizes-add
                            @save-size="onSaveSize"
                        />
                    </b-collapse>

                    <awesome-table
                        :labels="labels"
                        :items="sizes"
                        :options="{
                            drag: true,
                            sort: true,
                            isCard: false,
                            addButton: false,
                            searchInput: false,
                            link: false,
                            tableClass: 'table-sm'
                        }"
                        :pagination="pagination"
                        class="mt-3"
                        @row-click="showUpdateModal"
                        @pagination-change="onPaginationChange"
                        @elements-moved="onElementsMoved"
                    >
                        <template #[`items.actions`]="{ item }">
                            <a
                                href="#"
                                class="action-icon"
                                @click.stop.prevent="onDeleteSize(item.id)"
                            >
                                <i class="far fa-trash-alt" />
                            </a>
                        </template>
                    </awesome-table>
                </div>
            </div>
        </div>

        <update-modal
            :id="'sizeUpdateModal'"
            :size="sizeToUpdate"
            @updated="onSizeUpdated"
            @hidden="onHideUpdateModal"
        />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import AttributesSizesAdd from '@components/attributes/AttributesSizesAdd';
import UpdateModal from '@components/attributes/UpdateModal';

export default {
    page: {
        title: 'Attributes',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        AttributesSizesAdd,
        UpdateModal
    },

    data() {
        return {
            pageTitle: 'Product Attributes',
            breadcrumbsItems: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Product Attributes',
                    active: true
                }
            ],
            sizes: [],
            sizeToUpdate: null,
            labels: [
                'size',
                'eu_size_men',
                'eu_size_women',
                { value: 'actions', sortable: false }
            ],
            pagination: {
                currentPage: 1,
                perPage: 10,
                total: 0,
                sortBy: 'created_at',
                descending: true
            }
        };
    },

    async mounted() {
        this.sizes = await this.getSizes();
        this.sizes = this.sizes.sort((a, b) => a.order - b.order);
    },

    methods: {
        ...mapActions({
            getSizes: 'productSizes/index',
            createSize: 'productSizes/create',
            deleteSize: 'productSizes/delete',
            changeSizesOrder: 'productSizes/changeOrder'
        }),

        async onSaveSize(data) {
            try {
                const newSize = await this.createSize(data);
                this.sizes = [...this.sizes, newSize];

                this.$toaster('Size has been added!');
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.status === 422) {
                    message = err.response.data.error;
                }

                this.$toasterError(message);
            }
        },

        showUpdateModal(size) {
            this.sizeToUpdate = size;
            this.$bvModal.show('sizeUpdateModal');
        },

        onSizeUpdated(size) {
            const sizeIndex = this.sizes.findIndex(
                item => item.id === size.id
            );

            if (sizeIndex === -1) {
                return;
            }

            this.sizes[sizeIndex] = size;
            this.sizes = [...this.sizes];
        },

        async onDeleteSize(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this size?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteSize(id);

                this.sizes = this.sizes.filter(item => item.id !== id);

                this.$toaster('Size has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onHideUpdateModal() {
            this.sizeToUpdate = null;
        },

        async onElementsMoved(elements) {
            try {
                await this.changeSizesOrder(elements);

                this.$toaster('Order has been changed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };
        }
    }
};
</script>
