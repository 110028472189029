<template>
    <b-modal
        :id="id"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template #modal-title>
            Update Size
        </template>

        <div class="custom-modal-text">
            <div class="row">
                <div class="form-group col-12">
                    <label for="size">Size</label>
                    <input
                        id="size"
                        v-model.number="data.size"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': $v.data.size.$error }"
                    />
                </div>
                <div class="form-group col-6">
                    <label for="eu_size_men">EU Size Men</label>
                    <input
                        id="eu_size_men"
                        v-model.number="data.eu_size_men"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': $v.data.eu_size_men.$error }"
                    />
                </div>
                <div class="form-group col-6">
                    <label for="eu_size_women">EU Size Women</label>
                    <input
                        id="eu_size_women"
                        v-model.number="data.eu_size_women"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': $v.data.eu_size_women.$error }"
                    />
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="onSubmit"
                >
                    Update
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        size: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            data: {}
        };
    },

    validations: {
        data: {
            size: { required },
            eu_size_men: { required },
            eu_size_women: { required }
        }
    },

    methods: {
        ...mapActions({
            updateSize: 'productSizes/update',
        }),

        async openModalHandler() {
            await this.$nextTick();

            if (this.size) {
                this.data = { ...this.size };
            }
        },

        async onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const size = await this.updateSize(this.data);

                this.$emit('updated', size);

                this.$toaster('Size has been updated!');

                this.$v.$reset();
                this.hideModal();
            } catch (err) {
                console.error(err);

                let message = null;

                if (err.response.status === 422) {
                    message = err.response.data.error;
                }

                this.$toasterError(message);
            }
        },

        hideModal() {
            this.$bvModal.hide(this.id);
            this.$emit('hidden');
            this.data = {};
        }
    }
};
</script>
